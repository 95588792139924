import React, { useState, useEffect } from 'react';
import './nav.css';

const NavMenu = () => {
	const [menuIsActive, setMenuIsActive] = useState(false);

	function navClose(menuOpen) {
		const hamburgerSpans = document.getElementsByClassName('hamburger');
		let yShift = 0;
		let rotate = 0;
		let opacity = 1;
		if (!menuOpen) {
			rotate = 45;
			opacity = 0;
			yShift = 10;
		}
		hamburgerSpans[0].style.transform = `translateY(${yShift}px)`;
		hamburgerSpans[0].style.transform += `rotate(${rotate}deg)`;
		hamburgerSpans[2].style.transform = `translateY(-${yShift}px)`;
		hamburgerSpans[2].style.transform += `rotate(-${rotate}deg`;
		hamburgerSpans[1].style.opacity = opacity;
	}

	const handleNavMenu = () => {
		const navMenu = document.querySelector('.drop-nav');

		if (!menuIsActive) {
			setMenuIsActive(true);
			navMenu.style.top = '0';
			navMenu.style.opacity = '1';
			navClose(menuIsActive);
		} else {
			setMenuIsActive(false);
			navMenu.style.top = '-1500px';
			navMenu.style.opacity = '0';
			navClose(menuIsActive);
		}
	};

	const handleScroll = (page) => {
		page = page.toString().toLowerCase();
		const targetPage = document.querySelector(`.${page}`);
		targetPage.scrollIntoView();
		handleNavMenu();
	};

	useEffect(() => {
		// sets the nav height so that the nav hamburger menu sticks to the top of the contact page
		const navTop = document.querySelector('.contact');
		const r = document.querySelector(':root');
		const navTopPos = navTop.offsetTop;
		r.style.setProperty('--navHeight', navTopPos + 80 + 'px');
	}, []);

	return (
		<>
			<nav className='nav-container'>
				<div
					onClick={handleNavMenu}
					className='hamburger-container'>
					<span className='hamburger'></span>
					<span className='hamburger'></span>
					<span className='hamburger'></span>
				</div>

				<div className='drop-nav'>
					<li
						className='nav-li'
						onClick={(e) => handleScroll(e.target.innerText)}>
						Home
					</li>
					<li
						className='nav-li'
						onClick={(e) => handleScroll(e.target.innerText)}>
						About
					</li>
					<li
						className='nav-li'
						onClick={(e) => handleScroll(e.target.innerText)}>
						Projects
					</li>
					<li
						className='nav-li'
						onClick={(e) => handleScroll(e.target.innerText)}>
						Contact
					</li>
				</div>
			</nav>
		</>
	);
};

export default NavMenu;
