import { keyframes } from '@emotion/react';

export const fadeUp = keyframes`
	  from {
	    opacity: 0;
	    transform: translate3d(0, 30px, 0);
	  }

	  to {
	    opacity: 1;
	    transform: translate3d(0, 0, 0);
	  }
	`;

export const fadeDown = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -30px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    `;
