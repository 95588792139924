import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';

const EmailForm = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		try {
			emailjs
				.sendForm(
					process.env.REACT_APP_EMAILJS_SERVICE_ID,
					process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
					form.current,
					process.env.REACT_APP_EMAILJS_PUBLIC_ID
				)
				.then(
					(result) => {
						console.log(result);

						document.querySelector('.success-alert').style.top = '25px';
						e.target.reset();

						setTimeout(() => {
							document.querySelector('.success-alert').style.top = '-100px';
						}, 4000);
					},
					(error) => {
						console.error(error);
						document.querySelector('.error-alert').style.top = '25px';
						console.error(error);

						setTimeout(() => {
							document.querySelector('.error-alert').style.top = '-100px';
						}, 4000);
					}
				);
		} catch (error) {
			document.querySelector('.error-alert').style.top = '25px';
			console.error(error);

			setTimeout(() => {
				document.querySelector('.error-alert').style.top = '-100px';
			}, 4000);
		}
	};
	return (
		<>
			<form
				ref={form}
				onSubmit={sendEmail}
				className='form-wrapper'>
				<input
					name='user_name'
					type='text'
					placeholder='Name'
					required
				/>
				<input
					name='user_email'
					type='email'
					placeholder='Email'
					required
				/>
				<textarea
					name='message'
					placeholder='Type Message Here...'></textarea>
				<button
					type='submit'
					className='send-btn'>
					Send Message
					<span
						className='material-symbols-outlined'
						id='msg-btn'>
						arrow_right_alt
					</span>
				</button>
			</form>
		</>
	);
};

export default EmailForm;
