export const fadeOptions = {
	delay: 250,
	duration: 550,
	triggerOnce: true,
	fraction: 0.25,
};

// function that handles the switching of both the about and project elements
export function handleSelectionChange(val, id, tabHeight, selection, object) {
	// because the project is right aligned and the about page is left aligned,
	// need different x offset for the highlight div
	let offsetX = -3.5;
	if (selection === 'project') {
		offsetX = 3.5;
	}

	const highlightDiv = document.querySelector(`.${selection}-highlight`);
	const selectedId = document.getElementById(id);
	const panelId = document.querySelector(`#${id}text`);
	// for all siblings of selected element, remove selected class
	[...selectedId.parentElement.children].forEach((object) => {
		object.classList.remove(`selected-${selection}`);
	});
	// add selected class to the selected element
	selectedId.classList.add(`selected-${selection}`);
	// for all siblings of panel, remove show class
	[...panelId.parentElement.children].forEach((panel) => panel.classList.remove(`show-${selection}`));
	// add show class to selected panel
	panelId.classList.add(`show-${selection}`);
	// move the highlight div to the correct position based off tabHeight, val and offsetX.
	tabHeight = getTabHeight(selection, object);
	highlightDiv.style.transform = `translate(${offsetX}px, calc((${val} * ${tabHeight}px) - 2px) )`;
}

// function that will get the tab height depending on the selected class and object that we pass to the function
export function getTabHeight(selection, object) {
	// get count of keys in the object
	const count = Object.keys(object).length;
	const container = document.querySelector(`.${selection}-container`);
	// calculate tab height by taking the size of the selected container and divide by len of object
	const tabHeight = container.clientHeight / count;
	// change the tabHeight variable in the :root css(index.css)
	const r = document.querySelector(':root');
	r.style.setProperty(`--${selection}TabHeight`, tabHeight + 3 + 'px');
	// return the tab Height
	return tabHeight;
}
