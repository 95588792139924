import { React, useEffect, useState } from 'react';
import './project.css';
import { Fade } from 'react-awesome-reveal';
import { fadeUp } from '../customAnimation';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import { handleSelectionChange, getTabHeight, fadeOptions } from '../customFunctions';

const ProjectPage = () => {
	const projects = {
		project1: {
			name: 'Playlist App',
			description:
				'Vue.js application that serves as a music player for content from youtube, spotify, and soundcloud - all in one convenient location',
			path: 'https://music.iamseanbart.com/Example/Best%20Music/dQw4w9WgXcQ',
			tech: ['Vue.js', 'AWS Lambda', 'Node.js'],
		},
		project2: {
			name: 'Web Time Manager',
			description:
				'Web Time Manager is a chrome extension that allows you to track the time you spend each day on your chrome browser. It also allows you to set timers to limit the amount of time you spend on certain websites.',
			path: 'https://chrome.google.com/webstore/detail/web-time-manager/fompndjgkefggpakilkndcejnfikdccc',
			github: 'https://github.com/koltonostler/web-time-manager',
			tech: ['Chrome Extension', 'Chrome API', 'JavaScript', 'Webpack'],
		},
		project3: {
			name: 'Golf GPS Mobile',
			description:
				'An Android application that utilizes the location services of your phone to calculate exact yardages from your current location to a specified golf hole.',
			path: 'https://www.youtube.com/watch?v=sXMriEYPeRQ',
			github: 'https://github.com/koltonostler/golf-gps-mobile',
			image: '/images/Golf-GPS.png',
			tech: ['React Native', 'Google Firebase', 'No-SQL Database'],
		},
		project4: {
			name: 'Braille Generator',
			description: (
				<>
					<p>
						I challenged myself to build a ReactJS/TypeScript application in only 1 day. Braille Generator is the
						product of that challenge. Built using Next.js, this application will translate any english input
						into{' '}
						<a
							href='https://www.perkins.org/12-things-you-probably-dont-know-about-braille/#:~:text=In%20uncontracted%20braille%2C%20every%20word,they%20learn%20the%20contracted%20version.'
							className='braille-link'
							target='_blank'
							rel='noreferrer'>
							uncontracted braille
						</a>
						.
					</p>
				</>
			),
			path: 'https://braille.koltonostler.com',
			github: 'https://github.com/koltonostler/braille-generator',
			tech: ['ReactJS', 'TypeScript', 'Next.js', 'Dynamic API Routing'],
		},
		project5: {
			name: 'Typing WPM',
			description:
				'A timed typing test that uses Axios to fetch a quote from an API for you to type, then measures your Net WPM, Accuracy, and personal WPM record.',
			path: 'https://wpm.koltonostler.com',
			github: 'https://github.com/koltonostler/TypingWPM',
			image: '/images/typing.png',
			tech: ['ReactJS', 'JavaScript', 'Axios'],
		},
		project6: {
			name: 'Plinko',
			description:
				'A 2d Unity game modeled after the popular arcade style gambling game found on Stake.com.  Bet on each ball dropped and see if you can hit the jackpot. ',
			path: 'https://plinko.koltonostler.com',
			image: '/images/plinko.png',
			tech: ['C#', 'Unity', 'OOP - Singleton Design Pattern'],
		},
		project7: {
			name: 'Shrinking Snake',
			description:
				'My personal spin on an arcade classic.  In addition to the snake getting longer as you collect food, the playing area also gets progressively smaller, adding to the difficulty.',
			path: 'https://snek.koltonostler.com',
			github: 'https://github.com/koltonostler/Snake',
			image: '/images/snake.png',
			tech: ['JavaScript', 'Event-Driven Programming'],
		},
		project8: {
			name: 'Loan Repayment Calculator',
			description:
				'Calculate the fastest and most efficient strategy to get out of debt. This application calculates the repayment of multiple loans by comparing 3 different strategies to pay off your debt: 1) Avalanche Approach, 2) Snowball Approach, and 3) Min Payment Only.',
			path: 'https://loan-repayment-calculator-koltonostler.vercel.app/',
			github: 'https://github.com/koltonostler/loan-repayment-calculator',
			tech: ['Svelte', 'PicoCSS', 'Chart.js'],
		},
		project9: {
			name: 'Tic-Tac-Toe',
			description:
				'My first project ever, which helped me learn about the basics of JavaScript and how to use DOM manipulation in web applications. Dark theme for added style!',
			path: 'https://tictac.koltonostler.com',
			github: 'https://github.com/koltonostler/TicTacToe',
			image: '/images/tictac.png',
			tech: ['JavaScript', 'Dark-Theme'],
		},
		project10: {
			name: 'In Progress...',
			description:
				'Currently working on a DnD Map Creator with a friend that will allow users to create and edit custom DnD Maps.  The end result will allow Dungeon Masters and Players to have quick and easy access to maps for notes, terrain info, etc. Also working on a C# RPG Game and Chat App using Vue.js and Node.js.',
			tech: [
				'Vue',
				'Laraval',
				'PHP',
				'Node.js',
				'Web Sockets',
				'OAuth 2.0',
				'MySQL',
				'C#',
				'OOP',
				'Command Pattern',
				'Observer Pattern',
			],
		},
	};

	const GHLink = (link) => {
		if (!link.link) return;
		else {
			return (
				<a
					className='github'
					href={link.link}
					target='_blank'
					rel='noreferrer'>
					<FaGithub
						className='github-icon'
						title='GitHub Link'
						size={50}></FaGithub>
				</a>
			);
		}
	};

	const ExtLink = (link) => {
		if (!link.link) return;
		else {
			return (
				<a
					className='ext-link'
					href={link.link}
					target='_blank'
					rel='noreferrer'>
					<FaExternalLinkAlt
						className='ext-icon'
						title='Project Link'
						size={40}></FaExternalLinkAlt>
				</a>
			);
		}
	};

	// ##### Handle Tab Height #####

	const [projectTabHeight, setProjectTabHeight] = useState();

	useEffect(() => {
		// setsTabHeight after DOM elements are loaded
		setProjectTabHeight(getTabHeight('project', projects));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='projects'>
				<Fade
					keyframes={fadeUp}
					delay={fadeOptions.delay}
					duration={fadeOptions.duration}
					triggerOnce={fadeOptions.triggerOnce}
					fraction={fadeOptions.fraction}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<div className='projects-container'>
						<h2 className='h2-projects'>What I've Worked On</h2>
						<div className='content-wrapper-proj'>
							<div className='project-panel'>
								{/* map over projects and return description */}
								{Object.keys(projects).map((project, index) => {
									let className = 'project-text';
									let id = `project${index + 1}text`;
									if (index === 0) {
										className += ' show-project';
									}
									return (
										<div
											key={index}
											id={id}
											className={className}>
											<h2>{projects[project].name}</h2>
											<div className='project-description'>{projects[project].description}</div>
											<ul className='tech-container'>
												{Object.values(projects[project].tech).map((tech, index) => {
													return (
														<li
															className='tech-li'
															key={index}>
															{tech}
														</li>
													);
												})}
											</ul>
											<div className='icon-container'>
												<GHLink link={projects[project].github} />
												<ExtLink link={projects[project].path} />
											</div>
										</div>
									);
								})}
							</div>
							<div className='project-container'>
								{/* map over projects and return project name in button */}
								{Object.keys(projects).map((project, index) => {
									let className = 'project-tab';
									let id = `project${index + 1}`;
									if (index === 0) {
										className += ' selected-project';
									}
									return (
										<button
											onClick={(e) => {
												handleSelectionChange(
													e.target.value,
													e.target.id,
													projectTabHeight,
													'project',
													projects
												);
											}}
											title={projects[project].name}
											className={className}
											id={id}
											value={index}
											key={index}>
											{projects[project].name}
										</button>
									);
								})}
								<div className='project-highlight'></div>
							</div>
						</div>
					</div>
				</Fade>
			</div>
		</>
	);
};

export default ProjectPage;
