import React from 'react';
import './intro.css';
import { Fade } from 'react-awesome-reveal';
import { fadeUp } from '../customAnimation';
import { fadeOptions } from '../customFunctions';

const IntroPage = () => {
	return (
		<>
			<div className='home'>
				<Fade
					triggerOnce={fadeOptions.triggerOnce}
					direction='left'
					duration={fadeOptions.duration}>
					<img
						src='/images/fam_photo3.jpg'
						alt='Kolton with Family'
						className='profile-pic'
					/>
				</Fade>

				<section className='intro-wrapper'>
					<div className='intro'>
						<Fade
							delay={400}
							duration={fadeOptions.duration}
							triggerOnce={fadeOptions.triggerOnce}
							fraction={fadeOptions.fraction}
							keyframes={fadeUp}
							cascade>
							<h4 className='h4-intro'>
								<span className='hello-intro'>Hello</span>, my name is
							</h4>

							<h2 className='h2-intro'>Kolton Ostler</h2>

							<h4 className='h4-intro'>I'm a Web Developer.</h4>
							<p className='p-intro'>
								I love to create interesting, dynamic projects using modern Javascript frameworks.{' '}
							</p>
							<div className='btn-container'>
								<button
									onClick={() => {
										document.querySelector('.about').scrollIntoView();
									}}
									className='about-btn'>
									About Me
								</button>
							</div>
						</Fade>
					</div>
				</section>
			</div>
		</>
	);
};

export default IntroPage;
