import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './components/ContactPage/ContactPage';
import IntroPage from './components/IntroPage/IntroPage';
import NavMenu from './components/NavMenu/NavMenu';
import ProjectPage from './components/ProjectPage/ProjectPage';
import PreLoader from './components/PreLoader/PreLoader';
import Footer from './components/Footer/Footer.js';
import React, { useState, useEffect } from 'react';

function App() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		let randLoad = Math.floor(Math.random() * 2000) + 500;

		setTimeout(() => {
			setLoading(false);
		}, randLoad);
	}, []);

	return (
		<div className='App'>
			{loading ? (
				<PreLoader />
			) : (
				<div className='app-container'>
					<NavMenu />
					<IntroPage />
					<AboutPage />
					<ProjectPage />
					<ContactPage />
					<Footer />
				</div>
			)}
		</div>
	);
}

export default App;
