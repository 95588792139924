import { React, useState, useEffect } from 'react';
import './about.css';
import { Fade } from 'react-awesome-reveal';
import { fadeUp } from '../customAnimation';
import { handleSelectionChange, getTabHeight, fadeOptions } from '../customFunctions';
import { FaReact, FaVuejs, FaBootstrap, FaLaravel } from 'react-icons/fa';
import { SiJavascript, SiFirebase } from 'react-icons/si';
import { IconContext } from 'react-icons';

const AboutPage = () => {
	const handleScroll = (page) => {
		page = page.toString().toLowerCase();
		const targetPage = document.querySelector(`.${page}`);
		targetPage.scrollIntoView();
	};

	const roles = {
		role1: {
			name: 'Coder',
			description: (
				<>
					<p>
						I am currently employed as a Software Engineer at Studies Weekly, where I contribute to the
						development of an internal operations management tool using Nuxt/Vue.js for the frontend and PHP
						Laravel for the backend.
					</p>
					<p>
						I am a self taught engineer and am always eager to learn new technologies and become a better
						developer. I love to build personal <a className='link' onClick={(e) => handleScroll(e.target.innerText)}>projects</a>{' '}
						that focus around my interests/hobbies such as making games or web apps.
					</p>
					<p>Here are some of the technologies I've been working with:</p>
					<div className='tech-icons'>
						<IconContext.Provider value={{ size: 42 }}>
							<FaVuejs
								className='vue-icon'
								title='VueJS'
							/>
							<FaLaravel
								className='laravel-icon'
								title='Laravel'
							/>
							<FaReact
								className='react-icon'
								title='ReactJS'
							/>
							<SiJavascript
								className='js-icon'
								title='JavaScript'
							/>
							<FaBootstrap
								className='bootstrap-icon'
								title='Bootstrap 5'
							/>
							<SiFirebase
								className='firebase-icon'
								title='Google Firebase'
							/>
						</IconContext.Provider>
					</div>
				</>
			),
		},
		role2: {
			name: 'Father',
			description: (
				<>
					<p>
						I have two beautiful daughters, and one newborn son. Being a father has taught me more about
						patience, love and flexibility than anything else in my life, and watching my kids learn and grow is
						one of my greatest blessings.
					</p>
				</>
			),
		},
		role3: {
			name: 'Golfer',
			description: (
				<>
					<p>
						Golf is one of my lifelong passions. I previously worked in the golf industry both as an Assistant
						Golf Professional and then as a Sales Manager for the largest custom golf club manufacturer in the
						United States.
					</p>
					<p>
						I've competed in both amateur and professional golf tournaments, but these days I mostly enjoy just
						playing 9 holes by myself in the afternoon or going out with my kids to the driving range. I hope one
						day to have the time to get back into competitive golf.
					</p>
				</>
			),
		},
		role4: {
			name: 'Gamer',
			description: (
				<>
					<p>
						I grew up in the era of Pokémon Red and Blue on the Nintendo Gameboy, and Donkey Kong Country on the
						SNES. Some of my earliest and fondest memories are of playing The Legend of Zelda: Ocarina of Time at
						my friend's house down the street.
					</p>
					<p>
						My wife and I love to play games together and recently have been enjoying playing games such as Elden
						Ring, Balatro, and replaying some older games such as Kingdom Hearts.
					</p>

					<p>
						My older kids also love to play games on the Switch. They love Kirby, Pikmin, and all the Mario
						games.
					</p>
				</>
			),
		},
	};

	const [aboutTabHeight, setAboutTabHeight] = useState();
	let rolesNavOption = Object.keys(roles).map((role, index) => {
		let className = 'topic-tab';
		let id = `tab${index + 1}`;
		if (index === 0) {
			className += ' selected-topic';
		}
		return (
			<button
				onClick={(e) => {
					// getTabHeight('topic', roles);
					handleSelectionChange(e.target.value, e.target.id, aboutTabHeight, 'topic', roles);
				}}
				className={className}
				id={id}
				key={index}
				value={index}>
				{roles[role].name}
			</button>
		);
	});

	useEffect(() => {
		// setsTabHeight after DOM elements are loaded
		setAboutTabHeight(getTabHeight('topic', roles));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='about'>
				<Fade
					keyframes={fadeUp}
					delay={fadeOptions.delay}
					duration={fadeOptions.duration}
					triggerOnce={fadeOptions.triggerOnce}
					fraction={fadeOptions.fraction}
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<div className='about-container'>
						<h2 className='h2-about'>Who am I?</h2>
						<div className='content-wrapper'>
							<div className='topic-container'>
								{rolesNavOption}
								<div className='topic-highlight'></div>
							</div>
							<div className='topic-panel'>
								{Object.keys(roles).map((role, index) => {
									let className = 'topic-text';
									let id = `tab${index + 1}text`;
									if (index === 0) {
										className += ' show-topic';
									}
									return (
										<div
											className={className}
											id={id}
											key={index}>
											<div className='description'>{roles[role].description}</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</Fade>
			</div>
		</>
	);
};

export default AboutPage;
