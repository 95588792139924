import React from 'react';
import './contact.css';
import { Fade } from 'react-awesome-reveal';
import { fadeUp } from '../customAnimation.js';
import EmailForm from './EmailForm';
import { fadeOptions } from '../customFunctions';

const ContactPage = () => {
	function ContactFormAlert(props) {
		if (props.type === 'success') {
			return <div className='success-alert'>Message was sent successfully!</div>;
		} else if (props.type === 'error') {
			return <div className='error-alert'>Oops! Something went wrong. Message failed to send</div>;
		} else return "didn't work";
	}

	return (
		<>
			<div className='contact'>
				<div className='alert-container'>
					<ContactFormAlert type='success' />
					<ContactFormAlert type='error' />
				</div>
				<Fade
					keyframes={fadeUp}
					delay={fadeOptions.delay}
					duration={fadeOptions.duration}
					triggerOnce={fadeOptions.triggerOnce}>
					<h3 className='contactHeader'>Contact Me</h3>
					<p className='contact-msg'>
						If you would like to get in touch with me, please fill out the contact form below and I will get back
						to you soon.
					</p>
					<EmailForm />
				</Fade>
			</div>
		</>
	);
};

export default ContactPage;
