import React from 'react';
import './footer.css';

const Footer = () => {
	const handleScroll = (page) => {
		page = page.toString().toLowerCase();
		const targetPage = document.querySelector(`.${page}`);
		targetPage.scrollIntoView();
	};

	return (
		<div className='footer-wrapper'>
			<div className='footer-credits'>
				<span style={{ fontSize: '1.5rem', paddingRight: '.5rem' }}>&copy;</span> Kolton Ostler 2023
			</div>
			<button
				className='return-btn'
				onClick={() => {
					window.scrollTo(0, 0);
				}}>
				<span
					className='material-symbols-outlined'
					id='home-tab'>
					expand_less
				</span>
			</button>
			<div className='bottom-nav-container'>
				<div
					onClick={(e) => {
						handleScroll(e.target.innerText);
					}}
					className='bot-nav-link'>
					Home
				</div>
				<div
					onClick={(e) => {
						handleScroll(e.target.innerText);
					}}
					className='bot-nav-link'>
					About
				</div>
				<div
					onClick={(e) => {
						handleScroll(e.target.innerText);
					}}
					className='bot-nav-link'>
					Projects
				</div>
				<div
					onClick={(e) => {
						handleScroll(e.target.innerText);
					}}
					className='bot-nav-link'>
					Contact
				</div>
			</div>
		</div>
	);
};

export default Footer;
